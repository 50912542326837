import { inject, InjectionToken } from '@angular/core';

import { EndpointConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export const RETAILER_ITEMS_URI = new InjectionToken<string>('Retailer Items Core URI', {
  factory() {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrl}${config.apiUrlPrefix}/retailer-items`;
  },
});

export const RETAILER_ITEMS_BFF_URI = new InjectionToken<string>('Retailer Items BFF URI', {
  factory() {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/retailer-items`;
  },
});
